

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InlineEdit extends Vue {
  @Prop() public label: string;
  @Prop() public initValue: string;
  @Prop() public loading: boolean;
  public saved = false;

  public value = '';
  public get unsavedChanges() {
    return this.value !== this.initValue;
  }

  mounted() {
    this.value = this.initValue;
  }

  public save() {
    this.saved = true;
    this.$emit('save', this.value);
    (this.$refs['inputRef'] as HTMLElement).blur();
    setTimeout(() => this.saved = false, 100);
  }

  public cancel() {
    this.value = this.initValue;
  }
}
