


































































import { Component, Vue } from 'vue-property-decorator'
import VButton from '@/components/VButton.vue'
import InlineEdit from '@/components/InlineEdit.vue'
import AddClubMember from '@/pages/protected/AddClubMember.vue';
import ClubsApi from '@/api/Clubs'
import { Club } from '@/models/Club'
import UsersApi from '@/api/Users';
import User, { UserStatus } from '@/models/User';

@Component({
  components: { VButton, InlineEdit, AddClubMember }
})
export default class ManageClub extends Vue {
  public saveClubNameLoading = false;
  public club: Club | null = null;
  public addClubMemberActive = false;
  public statuses = UserStatus;

  mounted () {
    this.loadClub();
  }

  public get currentUserId() {
    return parseInt(localStorage.getItem('userId') ?? '');
  }

  public openAddMember() {
    this.$root.$emit('openModal', {
      title: 'Add Club Member',
      component: AddClubMember,
      events: {
        'success': (newUser: User) => this.newMemberSaved(newUser)
      }
    });
  }

  public editMember(user: User) {
    this.$root.$emit('openModal', {
      title: 'Edit Club Member',
      component: AddClubMember,
      props: {
        'editUser': user
      },
      events: {
        'success': () => this.loadClub()
      }
    })
  }

  public deleteMember(user: User) {
    this.$root.$emit('openModal', {
      title: 'Delete Club Member',
      content: `Are you sure you want to delete ${user.username}?`,
      buttons: [
        { text: 'No, Cancel', type: 'light' },
        { text: 'Yes, Delete', type: 'danger', handler: () => this.deleteMemberConfirmed(user) }
      ]
    });
  }

  public deleteMemberConfirmed(user: User) {
    UsersApi.deleteUser(user.id).then(() => {
      this.loadClub();
    }).catch((error) => {
      this.$root.$emit('handleError', error);
    });
  }

  public newMemberSaved(newUser: User) {
    this.loadClub();
    this.$root.$emit('openModal', {
      title: 'New Account Created!',
      content: `<b>Username:</b> ${newUser.username}<br/>
        <b>Email:</b> ${newUser.email}<br/>
        <b>Initial Password:</b> ${newUser.tempPassword}<br/><br/>
        <p class='w-80'>The new account will be activated after logging in for
        the first time and setting a new password.</p>`,
      buttons: [
        { text: 'Ok', type: 'info', handler: () => this.loadClub() }
      ]
    });
  }

  public loadClub() {
    ClubsApi.getCurrentClub().then((club: Club) => {
      this.club = club;
      this.$set(this.club, 'users', club.users);
    }).catch(error => {
      this.$root.$emit('handleError', error)
    });
  }

  public saveClubName (newVal: string) {
    if (this.club === null) return;
    this.saveClubNameLoading = true;
    this.club.name = newVal;
    ClubsApi.updateClub({ id: this.club.id, name: this.club.name }).then((response) => {
      console.log(response);
    }).catch((error) => {
      this.$root.$emit('handleError', error);
    }).finally(() => {
      this.saveClubNameLoading = false;
    });
  }
}
