












































import { Component, Prop, Vue } from 'vue-property-decorator';
import VButton from '@/components/VButton.vue';
import VMessage from '@/components/VMessage.vue';
import User, { UserRole } from '@/models/User';
import UsersApi from '@/api/Users';

@Component({
  components: { VButton, VMessage }
})
export default class AddClubMember extends Vue {
  @Prop() public editUser: User;
  public editMode = false;
  public name = '';
  public email = '';
  public roles = UserRole;
  public role: UserRole = UserRole.ATHLETE;
  public errors: string[] = [];
  public loading = false;

  mounted() {
    if (this.editUser !== undefined) {
      this.name = this.editUser.username;
      this.email = this.editUser.email;
      this.role = this.editUser.role;
      this.editMode = true;
    }
  }

  public submit() {
    this.errors = [];
    if (this.name.length === 0) {
      this.errors.push('Name field is required');
    }

    if (this.email.length === 0) {
      this.errors.push('Email field is required');
    }

    const re = /^[^\s@]+@[^\s@]+$/
    if (this.email.length > 0 && !re.test(this.email)) {
      this.errors.push('Email field is invalid');
    }

    if (this.errors.length > 0) return;

    if (this.editMode) {
      this.saveEditUser();
    } else {
      this.saveNewUser();
    }
  }

  public saveEditUser() {
    this.loading = true;
    const data = {
      id: this.editUser.id,
      email: this.email,
      username: this.name,
      role: this.role
    };

    UsersApi.editUser(data).then(() => {
      this.loading = false;
      this.$emit('close');
      this.$emit('success');
    }).catch((error) => {
      if (error.response.data.message) {
        this.errors.push(error.response.data.message);
      } else {
        this.$root.$emit('handleError', error);
      }
      this.loading = false;
    });
  }

  public saveNewUser() {
    this.loading = true;
    const data = { email: this.email, username: this.name, role: this.role };
    UsersApi.createUser(data).then((newUser: User) => {
      this.loading = false;
      this.$emit('close');
      this.$emit('success', newUser);
    }).catch((error) => {
      if (error.response.data.message) {
        this.errors.push(error.response.data.message);
      } else {
        this.$root.$emit('handleError', error);
      }
      this.loading = false;
    });
  }

  public close() {
    this.$emit('close');
  }
}
