var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"text-center"},[_vm._v("Manage Club")]),(_vm.club !== null)?_c('div',{staticClass:"bg-gray-600 p-3 mt-2 rounded-md"},[_c('div',{staticClass:"w-3/4 mx-auto relative"},[_c('inline-edit',{attrs:{"label":"Club Name","initValue":_vm.club.name,"loading":_vm.saveClubNameLoading},on:{"save":_vm.saveClubName}}),_c('hr',{staticClass:"my-6"}),_c('div',{staticClass:"mb-2 flex justify-between items-baseline"},[_c('h2',{staticClass:"text-xl"},[_vm._v("Club Members")]),_c('div',{staticClass:"inline-block"},[_c('v-button',{attrs:{"type":"success","iconRight":"plus"},nativeOn:{"click":function($event){return _vm.openAddMember()}}},[_vm._v(" Add Club Member ")])],1)]),_c('div',{staticClass:"bg-gray-800 rounded-md"},[_vm._m(0),_vm._l((_vm.club.users),function(user,i){return _c('div',{key:i,staticClass:"p-2 grid grid-cols-5",class:{
            'rounded-b-md': i === _vm.club.users.length - 1,
            'bg-gray-700': i % 2 === 1,
            'bg-gray-800': i % 2 === 0
          }},[_c('div',{staticClass:"flex self-center"},[_vm._v(_vm._s(user.username))]),_c('div',{staticClass:"flex self-center"},[_vm._v(_vm._s(user.email))]),_c('div',{staticClass:"flex self-center"},[_vm._v(_vm._s(user.role))]),(user.status === _vm.statuses.PENDING)?_c('div',{staticClass:"flex self-center cursor-pointer text-yellow-600",on:{"click":function($event){return _vm.newMemberSaved(user)}}},[_vm._v(" "+_vm._s(user.status)+" ")]):_vm._e(),(user.status !== _vm.statuses.PENDING)?_c('div',{staticClass:"flex self-center",class:{
              'text-green-600': user.status === _vm.statuses.ACTIVE,
              'text-red-600': user.status === _vm.statuses.INACTIVE,
              }},[_vm._v(" "+_vm._s(user.status)+" ")]):_vm._e(),_c('div',{staticClass:"flex flex-g"},[_c('v-button',{attrs:{"classList":"mr-2","dense":"","outlined":"","type":"info","iconRight":"edit"},nativeOn:{"click":function($event){return _vm.editMember(user)}}},[_vm._v("Edit")]),_c('v-button',{attrs:{"type":"danger","dense":"","outlined":"","iconRight":"trash","disabled":user.id === _vm.currentUserId},nativeOn:{"click":function($event){return _vm.deleteMember(user)}}},[_vm._v(" Delete ")])],1)])})],2)],1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid grid-cols-5 font-bold p-2 border-b border-white"},[_c('div',[_vm._v("Username")]),_c('div',[_vm._v("Email")]),_c('div',[_vm._v("Role")]),_c('div',[_vm._v("Status")]),_c('div',[_vm._v("Actions")])])}]

export { render, staticRenderFns }